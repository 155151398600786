import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => {
  const browser = typeof window !== "undefined" && window

  return (
    <Layout>
      {browser && (
        <>
          <SEO title="404: Not found" />
          <div className="error-container">
            <h1>NOT FOUND</h1>
            <p>You just hit a route that doesn&#39;t exist...</p>
            <p>
              Check the paths again. Follow this path format /[Product ID]/[Plan
              ID]/[Cycle ID]
            </p>
          </div>
        </>
      )}
    </Layout>
  )
}

export default NotFoundPage
